var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"mb-2 justify-space-between align-center",class:{ 'flex-column': _vm.$vuetify.breakpoint.smAndDown }},[_c('div',{staticClass:"text-h5 mc-font mt-4 mb-4 d-flex align-center",class:{ 'justify-space-between': _vm.$vuetify.breakpoint.smAndDown },style:({ whiteSpace: 'nowrap' })},[_vm._v(" "+_vm._s(_vm.folderName ? _vm.folderName : _vm.$t('allBookingPagesTitle'))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":_vm.emitAddNewBookingPage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("fas fa-plus-circle")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('addNewBookingPage')))])])],1),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',[_c('v-toolbar',{attrs:{"outlined":"","rounded":"","flat":"","dense":""}},[_c('v-toolbar-title',{staticClass:"d-flex flex-grow-1"},[_c('v-btn-toggle',{attrs:{"color":"primary","dense":"","group":""}},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleSearchInput.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fas fa-search")])],1),_c('v-text-field',{ref:"searchInputRef",class:_vm.$vuetify.rtl ? 'input-text-right' : 'input-text-left',style:({
                opacity: _vm.isSearchInputActive ? '1' : '0',
                width: !_vm.isSearchInputActive ? '0' : '',
                transition: 'all 400ms ease'
              }),attrs:{"dir":_vm.$vuetify.rtl ? 'rtl' : 'ltr',"hide-details":"","dense":"","flat":"","solo":"","value":_vm.currentFilters.q,"placeholder":_vm.$tc('search')},on:{"input":_vm.searchBookingList}})],1),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSearchInputActive),expression:"!isSearchInputActive"}],attrs:{"vertical":""}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSearchInputActive),expression:"!isSearchInputActive"}],style:({
              opacity: _vm.isSearchInputActive ? 0 : 1,
              transition: 'all 400ms ease'
            }),attrs:{"items":_vm.orderTypeItems,"item-text":"text","item-value":"key","value":_vm.currentFilters.order_type,"hide-details":"","placeholder":_vm.$tc('sortType'),"solo":"","dense":"","flat":"","item-color":"info"},on:{"change":_vm.orderTypeChange}}),_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSearchInputActive),expression:"!isSearchInputActive"}],attrs:{"vertical":""}}),_c('v-select',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSearchInputActive),expression:"!isSearchInputActive"}],style:({
              opacity: _vm.isSearchInputActive ? 0 : 1,
              transition: 'all 400ms ease'
            }),attrs:{"items":_vm.orderByItems,"value":_vm.currentFilters.order_by,"item-text":"text","item-value":"key","hide-details":"","placeholder":_vm.$tc('sortBy'),"solo":"","dense":"","flat":"","item-color":"info"},on:{"change":_vm.orderByItemsChange}})],1),_c('v-toolbar-items',[_c('v-divider',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSearchInputActive),expression:"!isSearchInputActive"}],attrs:{"vertical":""}}),_c('v-btn-toggle',{attrs:{"color":"primary","dense":"","group":""}},[_c('v-btn',{attrs:{"value":_vm.gridLayout,"text":""},on:{"click":function($event){$event.stopPropagation();return _vm.toggleBookingListLayout.apply(null, arguments)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.gridLayout ? 'fas fa-bars' : 'fas fa-th'))])],1)],1)],1)],1)],1):_c('v-btn',{staticClass:"d-flex justify-space-between",attrs:{"block":"","outlined":"","depressed":"","color":"#555"},on:{"click":_vm.openFoldersBottomSheet}},[_c('span',[_c('v-icon',{staticClass:"mr-2 ml-2"},[_vm._v("far fa-folder")]),_c('span',[_vm._v(_vm._s(_vm.$tc('folders')))])],1),_c('span',[_c('v-icon',{staticClass:"ml-2 mr-2",attrs:{"small":""}},[_vm._v("fas fa-chevron-down")])],1)]),_c('folders-bottom-sheet',{ref:"foldersBottomSheet"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }